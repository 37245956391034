<template>
  <div class="wrapper flex-between flex-col bg-white">
    <!-- 选择景区和日期 -->
    <div>
      <ul class="nav-wrap ph-2x bd-b flex-around pb-2x">
        <li
          class="nav-li pointer flex-shrink"
          :class="{ 'nav-act': navIndex == index }"
          v-for="(item, index) in navData"
          :key="index"
          @click="navClick(item, index)"
        >
          {{ item.subScenicName }}
        </li>
      </ul>
      <ul class="date-wrap bd round flex-between">
        <li
          v-for="(date, index) in dateData"
          :key="index"
          class="date-li text-c pointer relative"
          @click="dateClick(index)"
        >
          {{ date | date }}
          <span class="span" :class="{ 'date-act': dateIndex == index }">
            {{ date | date }}
          </span>
        </li>
      </ul>
    </div>
    <!-- 选择时段 -->
    <div class="flex-grow">
      <ul
        v-if="timeData && timeData.length"
        class="time-wrap flex-start flex-wrap"
      >
        <li
          class="time-li text-c pointer mb-2x ml-2x shadow"
          :class="{ disabled: item.disabled }"
          v-for="(item, index) in timeData"
          :key="index"
          @click="timeClick(item)"
        >
          <p class="gray">{{ item.timeIntervalName }}</p>
          <p class="bold font-xxl">
            {{ item.beginTime | date("hh:mm") }}
            -
            {{ item.endTime | date("hh:mm") }}
          </p>
          <p class="primary">余票：{{ item.remainingStock }}</p>
        </li>
      </ul>
      <a-empty v-else class="mt-2x" />
    </div>
    <!-- 底部按钮模块 -->
    <div class="footer">
      <div
        class="menu"
        @click="$router.push('/combination-product/TakeCombinationTicket')"
      >
        取票<span class="f-key" v-if="getshortcutKey">(F5)</span>
      </div>
      <!-- <div
        v-permission="'appCombinationProductTicketOperate'"
        class="menu"
        @click="$router.push('/home/ticket/TicketOperate')"
      >
        门票操作<span class="f-key" v-if="getshortcutKey">(F6)</span>
      </div> -->
    </div>
  </div>
</template>
<script>
import { subScenics } from "../../../api/merchant";
import { appointmentStockDate } from "../../../api/product";
import { mapGetters } from "vuex";
import { onkeydown } from "@/utils/global";

export default {
  name: "SaleList",
  components: {},
  props: {},
  data() {
    return {
      navIndex: 0, // 当前选中的景区
      navData: [], // 景区数据
      dateIndex: 0, // 当前选中的日期
      dateData: [], // 日期数据
      timeData: [], // 时段数据
      // 获取时段接口参数
      params: {
        subScenicId: "", // 子景区id
        subScenicName: "", // 子景区名称
        date: "" // 日期
      }
    };
  },
  computed: {
    ...mapGetters("setting", ["getshortcutKey"])
  },
  created() {
    this.getSubScenic();
  },
  // 开启键盘监听
  mounted() {
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },
  // 关闭键盘监听
  beforeDestroy() {
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  },
  methods: {
    // 获取子景区列表
    getSubScenic() {
      // 窗口端控制 filterSubScenic
      subScenics({ filterSubScenic: true })
        .then(res => {
          if (res) {
            this.navData = res.subScenics || [];
            this.params.subScenicId = this.navData[0].subScenicId;
            this.params.subScenicName = this.navData[0].subScenicName;
            // 生成日期
            this.genarateDate(3);
          }
        })
        .catch(() => {});
    },
    // 生成日期(返回时间戳数组): 获取当天到len天，连续的日期
    genarateDate(len) {
      const today = new Date();
      for (let i = 0; i < len; i++) {
        this.dateData.push(today.getTime() + 1000 * 60 * 60 * 24 * i);
      }
      this.params.date = this.dateData[0];
      // 根据景区和日期，获取时间段
      this.getTimeSlot();
    },
    // 获取时段
    getTimeSlot() {
      appointmentStockDate(this.params)
        .then(res => {
          this.timeData = res.map(item => {
            item.disabled =
              item.endTime < new Date().getTime() && this.dateIndex === 0;
            return item;
          });
        })
        .catch(() => {});
    },
    // 选择景区
    navClick(item, i) {
      this.navIndex = i;
      this.params.subScenicId = this.navData[i].subScenicId;
      this.params.subScenicName = this.navData[i].subScenicName;
      this.getTimeSlot();
    },
    // 选择日期
    dateClick(i) {
      this.dateIndex = i;
      this.params.date = this.dateData[i];
      this.getTimeSlot();
    },
    // 选择时段
    timeClick(timeData) {
      if (timeData.disabled) {
        this.$message.error("该时段不在可选范围");
        return;
      }
      // params: [ 事件， 子景区，日期， 时段]
      this.$emit(
        "hand-time",
        this.params.subScenicId,
        this.params.subScenicName,
        this.dateData[this.dateIndex],
        timeData
      );
    },
    // 处理键盘快捷键
    windowKeyDown(e) {
      if (!this.getshortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    // 根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 112:
          console.log("按了F1");
          break;
        case 113:
          console.log("按了F2");
          break;
        case 114:
          console.log("按了F3");
          break;
        case 115:
          console.log("按了F4");
          break;
        case 116:
          // console.log("按了F5");
          this.$router.push("/home/ticket/GetTicket");
          break;
        case 117:
          // console.log("按了F6");
          this.$router.push("/home/ticket/TicketOperate");
          break;
        case 118:
          console.log("按了F7");
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
// 景区
.nav-wrap {
  overflow-y: hidden;
  overflow-x: auto;
  .nav-li {
    padding: 14px 20px 2px;
    &.nav-act {
      border-bottom: 3px solid @primary;
    }
  }
}
// 日期选择
.date-wrap {
  width: 602px;
  margin: 24px auto;
  .date-li {
    width: 150px;
    padding: 10px 0;
    .span {
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      width: 0;
      padding: 10px 0;
    }
    .date-act {
      border-radius: @1x;
      width: 100%;
      height: 100%;
      transition: all 0.5s;
      background: @primary;
      color: #fff;
      text-align: center;
    }
  }
}
// 时段
.time-wrap {
  overflow-x: hidden;
  overflow-y: auto;
  .time-li {
    position: relative;
    padding: 14px 20px;
    border: 1px solid @primary;
    border-radius: @1x;
    &.disabled {
      box-shadow: none;
      border-color: @border;
      background: #f7f7f7;
      color: @disabled;
      cursor: not-allowed;
      .primary {
        color: @disabled;
      }
    }
  }
}
// 底部按钮
.footer {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  padding: 12px;
  .menu {
    position: relative;
    margin-right: 20px;
    flex-shrink: 0;
    width: 13%;
    padding: 0 4px;
    border: 1px solid @primary;
    height: 40px;
    line-height: 38px;
    text-align: center;
    color: @primary;
    border-radius: 20px;
    cursor: pointer;
    &.action {
      color: #ffffff;
      background-color: @primary;
    }
    &.disabled {
      border-color: @disabled;
      color: @disabled;
      cursor: not-allowed;
      background: #f5f5f5;
    }
    .iconfont {
      font-size: 22px;
      position: absolute;
      top: -15px;
      right: -13px;
    }
  }
}
</style>
