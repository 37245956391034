<template>
  <ticket-list permission="appCombinationTicketAction" />
</template>

<script>
import TicketList from "../home/ticket/TicketList";
export default {
  name: "CombinationTicketList",
  components: { TicketList },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
