<template>
  <div class="wrapper flex-col flex-between">
    <panel-head title="取票"></panel-head>
    <div class="main mt-2x relative">
      <KeyboardPro @ok="ok" v-model="numbers" type="3">
        <template v-slot:input="{ confirm }">
          <a-input
            size="large"
            allow-clear
            v-model="numbers"
            placeholder="订单号/取票号/手机号/身份证号/票号"
            @pressEnter="confirm()"
          >
            <a-icon slot="prefix" type="search" class="primary" />
            <span
              slot="addonAfter"
              class="iconfont iconcredentials_icon primary font-xxl"
            ></span>
          </a-input>
        </template>
      </KeyboardPro>
    </div>
  </div>
</template>

<script>
import panelHead from "@/components/common/PanelHead.vue";
import KeyboardPro from "@/components/common/KeyboardPro.vue";

import { orderCombinationticket } from "@/api/search";

export default {
  name: "TakeCombinationTicket",
  data() {
    return {
      numbers: "",
      isClick: false // 是否在执行
    };
  },
  components: {
    panelHead: panelHead,
    KeyboardPro: KeyboardPro
  },
  methods: {
    ok() {
      // 身份证读卡功能，会连续执行几次
      if (this.isClick) {
        return;
      }
      this.isClick = true;
      if (!this.numbers) {
        this.isClick = false;
        this.$message.warning("请先输入号码");
        return;
      }
      orderCombinationticket({
        keyword: this.numbers
      })
        .then(res => {
          if (res.length > 0) {
            this.$router.push({
              path: "/combination-product/TakeCombinationTicketList",
              query: {
                numbers: this.numbers
              }
            });
          } else {
            this.$message.warning("未查询到订单信息");
          }
          this.isClick = false;
        })
        .catch(() => {
          this.isClick = false;
        });
    }
  },
  watch: {
    numbers(newVal, oldVal) {
      //身份证格式
      const reg = /^[0-9]*X?$/;
      if (!reg.test(newVal) && newVal !== "") {
        this.numbers = oldVal;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  padding-top: 60px;
}
</style>
