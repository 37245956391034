<template>
  <section>
    <!-- 1产品 2子景区 -->
    <CombinationProductIndex v-if="appointmentSelection == 1" />
    <CombinationProductIndexTwo
      v-else-if="appointmentSelection == 2 || appointmentSelection == 3"
    />
  </section>
</template>
<script>
import CombinationProductIndexTwo from "./CombinationProductIndexTwo.vue";
import CombinationProductIndex from "./CombinationProductIndex.vue";
import { merchantSettingInfo } from "@/api/merchant";
export default {
  components: { CombinationProductIndexTwo, CombinationProductIndex },
  name: "ticketType",
  props: {},
  data() {
    return {
      appointmentSelection: 0 //景s约
    };
  },
  created() {
    this.querymerchantSettingInfo();
  },
  methods: {
    querymerchantSettingInfo() {
      merchantSettingInfo().then(res => {
        // 1">产品</ 2">子景区 3">景区</
        this.appointmentSelection = JSON.parse(
          res.merchantSetting
        ).appointmentSelection;
        sessionStorage.setItem(
          "appointmentSelection",
          this.appointmentSelection
        );
      });
    }
  }
};
</script>
