<template>
  <div class="wrapper flex-col flex-between">
    <!-- 顶部：已选择日期和时段 -->
    <panel-head @close="back">
      <p class="ml-2x primary font-xxl">
        <span class="text mr-4x">{{ subScenicName }}</span>
        已选：{{ playDate | date() }}
        <span class="ml-2x"
          >{{ timeData.beginTime | date("hh:mm") }} ~
          {{ timeData.endTime | date("hh:mm") }}</span
        >
      </p>
    </panel-head>

    <!-- 产品详情列表 -->
    <div class="main mt-2x" @scroll.passive="scroll" ref="scroll">
      <ul class="ul-wrap">
        <li
          class="flex-col align-center bd-b p-2x"
          v-for="(item, index) in liData"
          :key="index"
        >
          <div class="flex-between">
            <div>
              <!--单规格-->
              <div class="flex-start" v-if="item.whetherMultiSpec === 0">
                <img class="product-img" :src="item.skuImages" alt="产品图片" />
                <div class="ml-2x">
                  <h3>{{ item.name }}</h3>
                  <p class="mv-1x flex-start align-center">
                    <span class="red font-xxl" style="width: 100px;">{{
                      item.sellingPrice | money
                    }}</span>
                    <span class=" prompt-btn ml-1x">加购前须填写游客信息 </span>
                  </p>
                </div>
              </div>
              <!--多规格-->
              <div class="flex-start" v-else>
                <img
                  class="product-img"
                  :src="item.specCombination[item.index].skuImages"
                  alt="产品图片"
                />
                <div class="ml-2x">
                  <h3>
                    {{ item.name }} -
                    {{ item.specCombination[item.index].name }}
                  </h3>
                  <p class="mv-1x flex-start align-center">
                    <span class="red font-xxl" style="width: 120px;">{{
                      item.specCombination[item.index].sellingPrice | money
                    }}</span>
                    <span class="prompt-btn ml-1x">加购前须填写游客信息 </span>
                  </p>
                  <div class="tag-wrap flex-start flex-wrap">
                    <a-button
                      class="mr-2x mv-1x"
                      v-for="(tag, k) in item.specCombination"
                      :key="k"
                      :type="item.index === k ? 'primary' : 'default'"
                      @click="item.index = k"
                    >
                      {{ tag.name }}
                    </a-button>
                  </div>
                </div>
              </div>
            </div>
            <a-button
              class="align-end"
              type="primary"
              size="large"
              @click="addPlayer(item)"
            >
              加入购物车
            </a-button>
          </div>
          <p v-if="item.touristCategoryName" class="gray mt-2x text-1">
            游客类型限制：{{ item.touristCategoryName }}
          </p>
        </li>
      </ul>

      <a-empty v-show="liData.length === 0" class="mt-4x" />

      <p class="text-c gray mv-2x" v-show="noMore">我是有底线的~</p>
    </div>

    <!-- 填写游客信息弹窗 -->
    <TouristDialog
      :vis.sync="visible"
      @change="surePlayer"
      ref="touristDialog"
    ></TouristDialog>
  </div>
</template>
<script>
import TouristDialog from "./components/TouristDialog.vue";
import { productFrontCompositeProduct } from "../../../api/search";
import { moreMultiCart, multiCart } from "../../../api/cart";
import PanelHead from "../../../components/common/PanelHead";
import { selectTouristWindowInfoByProductId } from "@/api/member";

export default {
  name: "SaleDetail",
  components: { TouristDialog, PanelHead },
  props: {
    // 子景区id
    subScenicId: {
      type: String,
      default: ""
    },
    // 子景区id
    subScenicName: {
      type: String,
      default: ""
    },
    // 游玩日期
    playDate: {
      type: Number,
      default: 0
    },
    // 时段id
    timeData: {
      type: Object,
      default() {
        return {};
      }
    },
    // 购物车id
    cartId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      page: 1,
      size: 10,
      loading: false,
      noMore: false, // 没有更多了
      liData: [],
      visible: false,
      editPlayerItem: {} // 编辑的游客信息对象
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    //查询游客类型
    queryTourist(productIds) {
      selectTouristWindowInfoByProductId({ productIds: productIds }).then(
        res => {
          res.forEach(item => {
            this.liData.forEach(ite => {
              if (item.productId == ite.id) {
                ite.touristCategoryName = item.memberTouristManagementVOList
                  ? item.memberTouristManagementVOList
                      .map(temp => {
                        return temp.touristCategoryName;
                      })
                      .join("，")
                  : "";
              }
            });
          });
          this.$forceUpdate();
        }
      );
    },
    // 加载产品数据
    loadData() {
      this.loading = true;
      productFrontCompositeProduct({
        subScenicId: this.subScenicId,
        playDate: this.playDate,
        page: this.page,
        size: this.size // 暂时写死
      })
        .then(res => {
          const liData = res.records.map(item => {
            // 添加游客信息字段
            item.player = {
              name: "",
              phone: "",
              idCard: "",
              touristId: ""
            };
            item.index = 0;
            return item;
          });
          let productIds = res.records.map(item => {
            return item.id;
          });
          this.queryTourist(productIds.toString());
          this.liData = this.liData.concat(liData);
          if (this.liData.length === res.total) this.noMore = true;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    // 添加游客
    addPlayer(item) {
      this.editPlayerItem = item;
      this.$refs.touristDialog.queryTourist(item.id);
      this.visible = true;
    },
    // 确认游客信息
    surePlayer(form) {
      this.editPlayerItem.player = { ...form };
      this.addToCart(this.editPlayerItem);
    },
    addToCart(item) {
      let obj = {
        playDate: this.playDate,
        timeIntervalId: this.timeData.id,
        timeShareAppointmentRuleId: this.timeData.ruleId,
        // 多规格 item.specCombination === 1, 否则单规格
        productId: item.whetherMultiSpec
          ? item.specCombination[item.index].id
          : item.id,
        visitor: {
          visitorFieldList: [
            { id: "1380352491692818433", fieldValue: item.player.name }, // 姓名
            { id: "1380352491692818434", fieldValue: item.player.phone }, // 手机号
            { id: "1380460304389713921", fieldValue: item.player.idCard }, // 身份证
            { id: "1467786872876040193", fieldValue: "身份证" } // 身份证
          ],
          // touristId: item.player.touristId, // 游客类型
          visitorTypeId: item.player.visitorTypeId
        },
        subScenicId: this.subScenicId //子景区id 特惠产品要用到

        // ...item.player
      };
      let api = multiCart;
      if (this.cartId) {
        obj.id = this.cartId;
        api = moreMultiCart;
      }
      api(obj)
        .then(res => {
          this.$emit("update-cart", res.id);
          this.$message.success("添加成功");
        })
        .catch(() => {});
    },
    // 返回
    back() {
      this.page = 1;
      this.liData = [];
      this.noMore = false;
      this.$emit("hand-back", "SaleList");
    },
    // 加载更多
    scroll() {
      if (!this.noMore) {
        if (!this.loading) {
          const box = this.$refs.scroll;
          let clientHeight = box.clientHeight;
          let scrollTop = box.scrollTop;
          let scrollHeight = box.scrollHeight;
          if (scrollHeight - 100 < clientHeight + scrollTop) {
            this.page++;
            this.loadData();
          }
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.wrapper {
  overflow: hidden;
}

.ul-wrap {
  .product-img {
    width: 116px;
    height: 116px;
    border-radius: 12px;
  }
  .prompt-btn {
    font-size: 12px;
    color: @primary;
  }
  .tag-wrap {
    p {
      width: 80px;
      padding: 6px 0;
      text-align: center;
      border-radius: 4px;
    }
  }
}
</style>
