<template>
  <div class="wrapper flex-col flex-start">
    <panelhead title="取票"></panelhead>

    <section class="main mt-2x">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :rowKey="(record, index) => index"
        :pagination="false"
        :scroll="{ x: 1366, y: 600 }"
      >
        <!-- 下单时间 -->
        <span
          slot="createTime"
          slot-scope="row"
          :class="{ gray: row.whetherTakeTicket == 1 }"
        >
          {{ row.createTime | date }}
        </span>
        <!-- 取票状态 -->
        <template slot="whetherTakeTicket" slot-scope="row">
          <span class="primary" v-if="row.whetherTakeTicket == 0">
            未取票
          </span>
          <span class="gray" v-if="row.whetherTakeTicket == 1">
            已取票
          </span>
        </template>
        <!-- 游玩日期 -->
        <span
          slot="useDate"
          slot-scope="row"
          :class="{ gray: row.whetherTakeTicket == 1 }"
        >
          {{ row.useDate | date }}
        </span>
        <!-- 使用时段 -->
        <span
          slot="validTime"
          slot-scope="row"
          :class="{ gray: row.whetherTakeTicket == 1 }"
        >
          {{ row.appointmentPeriodStartTime | date }} ~
          {{ row.appointmentPeriodEndTime | date }}
        </span>
        <!-- 使用有效期 -->
        <span
          slot="validStart"
          slot-scope="row"
          :class="{ gray: row.whetherTakeTicket == 1 }"
        >
          {{ row.beginValidTime | date }} ~
          {{ row.endValidTime | date }}
        </span>

        <!-- 游客姓名 -->
        <span
          slot="playPersonName"
          slot-scope="row"
          :class="{ gray: row.whetherTakeTicket == 1 }"
        >
          {{ row.playPersonName ? row.playPersonName : "-" }}
        </span>
        <!-- 游客手机号 -->
        <span
          slot="playPersonPhone"
          slot-scope="row"
          :class="{ gray: row.whetherTakeTicket == 1 }"
        >
          {{ row.playPersonPhone ? row.playPersonPhone : "-" }}
        </span>
        <!-- 游客身份证 -->
        <span
          slot="playPersonIdCard"
          slot-scope="row"
          :class="{ gray: row.whetherTakeTicket == 1 }"
        >
          {{ row.playPersonIdCard ? row.playPersonIdCard : "-" }}
        </span>
        <!-- 操作 -->
        <template slot="operation" slot-scope="row">
          <p
            v-if="row.whetherTakeTicket == 0"
            class="blue oprate pointer"
            @click="takeTicket(row)"
          >
            取票
          </p>
          <p
            v-if="row.whetherTakeTicket == 1"
            class="blue oprate pointer"
            @click="makeUpTicket(row)"
          >
            补打
          </p>
        </template>
      </a-table>
    </section>

    <footer class="footer">
      <div>
        <a-button
          v-if="isShowAllBtn"
          type="primary"
          size="large"
          shape="round"
          @click="takeTicketAll"
          class="mr-3x"
          >全部取票</a-button
        >
        <a-button size="large" shape="round" @click="$router.go(-1)"
          >返回</a-button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import panelhead from "@/components/common/PanelHead.vue";
import {
  orderCombinationticket,
  orderSubcombinationprintinfo,
  orderCombinationprintinfo
} from "@/api/search";
import { ticketPrint } from "@/api/order";

import HardwareService from "@/common/domains/hardware-domain/hardwareService";

const columns = [
  {
    title: "下单时间",
    scopedSlots: { customRender: "createTime" },
    width: 120
  },
  {
    title: "主订单编号",
    dataIndex: "mainOrderId",
    width: 200
  },
  {
    title: "票号",
    dataIndex: "auxiliaryId",
    width: 130
  },
  {
    title: "组合产品名称",
    dataIndex: "productName",
    width: 130
  },
  {
    title: "游玩日期",
    scopedSlots: { customRender: "useDate" },
    width: 120
  },
  {
    title: "使用时段",
    scopedSlots: { customRender: "validTime" },
    width: 120
  },
  {
    title: "使用有效期",
    scopedSlots: { customRender: "validStart" },
    width: 200
  },
  {
    title: "取票状态",
    scopedSlots: { customRender: "whetherTakeTicket" },
    width: 90
  },
  {
    title: "销售渠道",
    dataIndex: "buyChannelsText",
    width: 130
  },
  {
    title: "游客姓名",
    scopedSlots: { customRender: "playPersonName" },
    width: 130
  },
  {
    title: "游客手机号",
    scopedSlots: { customRender: "playPersonPhone" },
    width: 130
  },
  {
    title: "游客身份证",
    scopedSlots: { customRender: "playPersonIdCard" },
    width: 180
  },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
    fixed: "right",
    width: 80
  }
];

export default {
  name: "TakeCombinationTicketList",
  props: {},
  components: {
    panelhead
  },
  data() {
    return {
      columns, // 门票取票table的列
      tableData: [], // 门票取票table数据
      numbers: "", //查询的号
      mainOrderId: "", //打印全部票时的主订单id
      isShowAllBtn: false, //是否显示批量取票
      auxiliaryInfoList: [] //改状态接口需提交参数
    };
  },
  created() {
    this.numbers = this.$route.query.numbers;
    this.loadData();
  },
  methods: {
    // 加载取票列表数据
    loadData() {
      orderCombinationticket({
        keyword: this.numbers
      })
        .then(res => {
          this.tableData = res || [];
          this.mainOrderId = res[0].mainOrderId;
          let filterData = res.map(item => {
            return item.whetherTakeTicket === 0;
          });
          //如果存在未取票的数据，显示批量取票的按钮
          if (filterData.includes(true)) {
            this.isShowAllBtn = true;
          }

          this.auxiliaryInfoList = res.map(item => {
            return {
              ifActivateNow: true,
              id: item.id
            };
          });

          console.log("---", this.auxiliaryInfoList);
        })
        .catch(() => {});
    },
    //取单张门票
    takeTicket(row) {
      orderSubcombinationprintinfo({
        orderId: row.orderId
      })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
            ticketPrint({
              channel: "",
              auxiliaryInfoList: [
                {
                  ifActivateNow: true, // 是否立即激活
                  id: row.id
                }
              ]
            })
              .then(() => {
                this.$message.success("取票完成");
                this.loadData();
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    //补打门票
    makeUpTicket(row) {
      orderSubcombinationprintinfo({
        orderId: row.orderId
      })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
          }
        })
        .catch(() => {});
    },
    //批量取票
    takeTicketAll() {
      orderCombinationprintinfo({
        orderId: this.mainOrderId
      })
        .then(res => {
          if (res) {
            HardwareService.printTicket(JSON.stringify(res));
            ticketPrint({
              channel: "",
              auxiliaryInfoList: this.auxiliaryInfoList
            })
              .then(() => {
                this.$message.success("取票完成");
                this.loadData();
                this.backHome();
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    // 取票完成，返回组合产品下单页面提示
    backHome() {
      this.$confirm({
        title: "温馨提示",
        content: "取票完成，点击按钮返回",
        okText: "返回组合产品下单页面",
        cancelText: "继续取票",
        onOk: () => {
          this.$router.replace("/combination-product");
        },
        onCancel: () => {
          this.$router.replace("/combination-product/TakeCombinationTicket");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
