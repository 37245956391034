<!--
  @name: 组合产品首页
-->

<template>
  <div class="combination-product-index">
    <!--三级路由-->
    <router-view />
  </div>
</template>
<script>
export default {
  name: "CombinationProductIndex",
  data() {
    return {
      slideName: "slide-fade"
    };
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped></style>
