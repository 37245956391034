<template>
  <div class="flex-between">
    <!--选择时段-->
    <sale-list
      v-show="comType === 'SaleList'"
      @hand-time="handTime"
      @changeTimeData="changeTimeData"
    ></sale-list>
    <!--选择产品加入购物车-->
    <sale-detail
      ref="SaleDetail"
      v-show="comType === 'SaleDetail'"
      :sub-scenic-id="subScenicId"
      :sub-scenic-name="subScenicName"
      :play-date="playDate"
      :time-data="timeData"
      :cart-id="cartId"
      @hand-back="handBack"
      @update-cart="updateCart"
    ></sale-detail>
    <!-- 右侧购物车 -->
    <CombinationCart ref="cart" @cancel-cart="cancelCart"></CombinationCart>
  </div>
</template>
<script>
import SaleList from "./SaleList.vue";
import SaleDetail from "./SaleDetail.vue";
import CombinationCart from "./components/CombinationCart.vue";
export default {
  name: "SaleIndex",
  components: { CombinationCart, SaleList, SaleDetail },
  props: {},
  data() {
    return {
      // 13809858591
      // 450422199011140813
      comType: "SaleList", // 组件名称，
      subScenicId: "", // 子景区id
      subScenicName: "", // 子景区名称
      playDate: 0, // 游玩日期
      timeData: {}, // 时段
      cartId: 0
    };
  },
  methods: {
    handTime(subScenicId, subScenicName, playDate, timeData) {
      this.comType = "SaleDetail";
      this.subScenicId = subScenicId;
      this.subScenicName = subScenicName;
      this.playDate = playDate;
      this.timeData = timeData;
      this.$nextTick(() => {
        this.$refs.SaleDetail.loadData();
      });
    },
    changeTimeData(timeData) {
      this.timeData = timeData;
    },
    // 组合产品列表及已选时间组件的切换
    handBack() {
      this.comType = "SaleList";
    },

    // 更新购物车
    updateCart(id) {
      this.cartId = id;
      this.$refs.cart.loadData(id);
    },
    // 取消购物车-整单取消
    cancelCart() {
      this.cartId = 0;
    }
  }
};
</script>
<style lang="less" scoped></style>
